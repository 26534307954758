import React from "react";
import { Paper, Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  footerContainer: {
    marginTop: "40px",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  flex: {
    display: "flex",
  },
  padRight: {
    paddingRight: "30px",
  },
  container: {
    padding: "45px",
  },
  grow1: {
    flexGrow: 1,
  },
  brand: {
    fontFamily: "Comic Sans MS",
  },
});

const Footer = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.footerContainer}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className={`${classes.container} ${classes.flex}`}>
              <div>
                <Typography>
                  All Rights Reserved | No responsibility taken for links to
                  external sites or any content beyond our control. |
                  Disclaimer: The content of this website is based upon opinions
                  of Positive Movement unless otherwise stated. Reasonable care
                  is taken to ensure accuracy. Errors and omissions excepted.
                  Individual articles, extracts, comments and any links to
                  external sites are based upon the opinions of the respective
                  author(s).
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12}>
            <div className={`${classes.flex} ${classes.padRight}`}>
              <div className={classes.grow1} />
              <div>
                <Typography noWrap className={classes.brand}>
                  &copy; POSITIVE MOVEMENT {new Date().getFullYear()}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default Footer;
