import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import trx from "../images/trx.jpg";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  header: {
    marginLeft: "45px",
  },
  article: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const ArticleTRX = () => {
  const classes = useStyles();
  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        TRX Suspension Training
      </Typography>
      <Paper className={classes.article}>
        <Typography className={classes.p}>
          TRX is a form of suspension training developed by the navy seals.
          Using leverages combined with bodyweight and gravity TRX training is
          an ideal exercise tool for a wide range of people from athletes aiming
          to develop strength, balance, flexibility and core stability
          simultaneously to individual recovering from injury and surgery.
          Cassie regularly incorporates TRX exercises into her performance
          enhancement and rehabilitation programmes. One of the many benefits of
          using the TRX is that its design means that it can be used anywhere,
          it can be attached to a door frame at home or attached to a tree in
          the park.
        </Typography>

        <Typography className={classes.p}>
          Cassie enjoys adapting TRX programmes for injury rehabilitation and
          also for athletes development. It is the perfect training tool to use
          at home, take on holiday or even set up for a quick lunch time workout
          at the office.
        </Typography>

        <Typography className={classes.p}>
          So why not have a personalised plan written for you to focus on your
          own goals that you can do anywhere at any time?
        </Typography>

        <img src={trx} className={classes.img} alt="trx" />
      </Paper>
    </div>
  );
};

export default ArticleTRX;
