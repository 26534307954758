import React from "react";

const ScrollToTop: React.FC<{}> = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  });

  return null;
};

export default ScrollToTop;
