import React from "react";
import { Typography, Grid, Paper, makeStyles, List } from "@material-ui/core";
import treatment from "../images/ExLaydown.gif";
import group from "../images/Exgroup.gif";
import cart from "../images/ExCart.gif";
import push from "../images/ExPUp.gif";
import back from "../images/back.jpg";
import friz from "../images/friz.jpg";
import perf from "../images/perf.jpg";
import massage from "../images/massage.jpg";
import Bullet from "../components/bullet";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  service: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  inline: {
    display: "inline",
  },
  image: {
    display: "inline-block",
    maxWidth: "100%",
  },
  imageHeader: {
    marginRight: "20px",
  },
  blurb: {
    marginTop: "20px",
  },
  icon: {
    minWidth: "45%",
  },
  header: {
    marginLeft: "45px",
  },
});

const Services = () => {
  const classes = useStyles();

  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        What We Offer
      </Typography>
      <Paper className={classes.service}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <img className={classes.imageHeader} src={treatment} alt="icon" />
            <Typography className={classes.inline} variant="h4">
              Treatment &amp; Rehabilitation
            </Typography>

            <Typography className={classes.blurb}>
              Following an assessment we will determine the appropriate plan of
              treatment.The return to performance is mediated via Injury
              Rehabilitation sessions involving a combination of corrective
              exercise, massage, hydrotherapy, postural re-education,
              electrotherapy. Areas of musculoskeletal pain and injury that can
              be treated include:
              <br />
              <List>
                {[
                  "Sports Injuries - Overuse injuries, sprains and strains.",
                  "Work related pain and injuries - Repetitive strain injuries, aches and pains as a result of prolonged and/or incorrect posture.",
                  "General Aches and Pain - Knee pain, acute, chronic and intermittent back pain.",
                  "Pre/Post Operative Rehabilitation - Prepare for surgery and to regain strength and function following surgery.",
                ].map((text) => (
                  <Bullet text={text} />
                ))}
              </List>
              <br />
              To request more information contact us
            </Typography>
          </Grid>
          <Grid item md={4}>
            <img src={back} className={classes.image} alt="backPain" />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.service}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <img className={classes.image} src={friz} alt="backPain" />
          </Grid>
          <Grid item md={8}>
            <img src={group} alt="icon" className={classes.imageHeader} />
            <Typography className={classes.inline} variant="h4">
              Prevention / Maintenance Programs
            </Typography>
            <Typography className={classes.blurb}>
              We use a range of assessment techniques to highlight the risks
              associated with injury and implement an appropriate plans to
              minimize these risks. This process involves the planning,
              implementation and interpretation of a pre-participation
              assessment, to identify potential factors that could lead to
              musculoskeletal injury and/or health related disorders. Typical
              groups of people who would benefit from this include: <br />
              <List>
                {[
                  "Older Adult - Wishing to maintain functional independence.",
                  "Office Worker - People who spend prolonged periods of time in the same position e.g. traveling or sitting at a computer, who wish to prevent postural dis-function.",
                  "Children/Younger Adult - Participating in regular sporting activities has many positive effects but also demands a lot from the human body. In children and young adults these demands are intensified on top of an already strained system due to the demands of growing. Our treatments offer a way of to reduce these effects.",
                ].map((text) => (
                  <Bullet text={text} />
                ))}
              </List>
              To request more information contact us
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.service}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <img src={cart} alt="icon" className={classes.imageHeader} />
            <Typography className={classes.inline} variant="h4">
              Performance Enhancement
            </Typography>
            <Typography className={classes.blurb}>
              Following assessment Cassie will use a range of methods necessary
              to reach a clients specific goals. Techniques used include
              corrective exercise training, manual therapy techniques, PNF, open
              and closed kinetic chain exercises, progressive propreoception
              exercises, muscle imbalance work, core strengthening gait
              assessment and re-education. Throughout the management of the
              client the management program will be monitored to account for any
              necessary modification to reach the clients goal.
              <br />
              <br /> To request more information contact us
            </Typography>
          </Grid>

          <Grid item md={4}>
            <img className={classes.image} src={perf} alt="performance" />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.service}>
        <Grid container spacing={2}>
          <Grid item md={4}>
            <img className={classes.image} src={massage} alt="massage" />
          </Grid>

          <Grid item md={8}>
            <img src={push} alt="icon" className={classes.imageHeader} />
            <Typography className={classes.inline} variant="h4">
              Sports Massage
            </Typography>
            <Typography className={classes.blurb}>
              Sports Massage (a form of deep tissue massage) helps maintain
              functional fitness. The benefits of a sports massage include:
              <br />
              <List>
                {[
                  "Improved lymphatic drainage",
                  "Improved blood supply",
                  "Reduces risk of injury",
                  "Reduction in post activity stiffness",
                ].map((text) => (
                  <Bullet text={text} />
                ))}
              </List>
              <br />
              Sports massages are not only beneficial for athletes but also for
              anyone who leads an active life, has a stressful jobs or generally
              feels tight.
              <br />
              <br /> To request more information contact us
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Services;
