import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import gait_golf from "../images/gait_golf.jpg";
import gait_kenyans from "../images/gait_kenyans.jpg";
import gait_analysis from "../images/gait-analysis.jpg";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  header: {
    marginLeft: "45px",
  },
  article: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const Article = () => {
  const classes = useStyles();
  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        Gait Analysis - Do I need it?
      </Typography>
      <Paper className={classes.article}>
        <Typography>
          Whether it's because I'm a runner, due to my involvement with RunFitUK
          or because more people are running (it's probably a combination of all
          3), I have noticed that I am seeing more and more runners in my
          clinic. Not just runners though, duathletes, triathletes and iron man
          athletes also. It seems everyone has suddenly realised how easy it is
          to get out there and run. Running is also key in other sports such as
          rugby, football, hockey etc. It's a key 'skill' yet one that very few
          of us are actually taught or try to improve. We crawl, we walk and
          then we run. There are that lucky few that make it look effortless,
          floating across the ground as if they weigh no more than a feather
          however in reality most of us are not so lucky and although we may
          enjoy running it is hard work. So what if we thought of running as a
          skill rather than something we just do?
        </Typography>

        <Typography className={classes.p}>
          If you are a golfer your are taught step by step how to perfect your
          swing, a tennis player spend hours working on their forehand and
          backhand, a swimmer does the same working endlessly on perfecting
          their stroke but runners just.. run! If you want to improve your
          running, why not look at how you run and work on improving it?
        </Typography>

        <img src={gait_golf} className={classes.img} alt="golf" />
        <img src={gait_kenyans} className={classes.img} alt="runners" />

        <Typography variant="h5">What's the right way to run?</Typography>

        <Typography className={classes.p}>
          I'm afraid there is no simple answer to that, we are all different. We
          grow up in different surroundings, our bodies are different shapes and
          sizes, we have different medical histories, different parents, the
          list could go on. As we are all different there is little point trying
          to fit all runners into the same mould but that is not to say that the
          way you currently run is the most efficient way for you. You may have
          picked up habits that your don't realise through past injuries, you
          might be running a certain way because your lifestyle away from
          running causes your muscles to tighten in a certain way (if you work
          at a desk or spend time driving this is probably you) or you may just
          be trying to run in a certain way because that's what someone told you
          to do!{" "}
        </Typography>
        <Typography className={classes.p}>
          To find the right way to run you need to know how YOU run and then
          look at what you could do to improve that. Working with your own
          body's strengths and weaknesses to build on what you have.{" "}
        </Typography>
        <Typography className={classes.p}>
          Here's an example - A really 'fit' runner who never gets out of breath
          and should in theory be able to run for miles. However in reality they
          can't run for more than 4 or 5 miles before their legs get tired and
          they get niggling injuries. This is nothing to do with fitness level,
          the problem is strength in some key muscles around their hips. This
          weakness causes other areas to be overloaded. This results in the
          runners potential being limited not by their fitness level but by by a
          weakness in a particular muscle or muscle group. If they didn't know
          this they would carry on training but no matter how many miles,
          intervals and hills they did they would unlikely be able to correct
          the problem. To be able to fix the problem it needs to be highlighted
          and then the runner can be given the tools to correct it you can not
          reach your potential.
        </Typography>
        <Typography className={classes.p}>
          That is a simplified example but highlights how as runners there may
          be significant advantages in viewing running as a skill that can be
          improved alongside the usual training plans that improve our fitness.
        </Typography>

        <Typography variant="h5">Gait analysis</Typography>

        <Typography className={classes.p}>
          If I had a pound for every time I have an injured athlete in my clinic
          who says that they don't know why they are injured as they had their
          gait analysed when they purchased their trainers I wouldn't be rich
          but I could certainly buy a few more pairs of trainers myself! Some of
          these are proper gait analysis' done by qualified experts who will
          take time and analyse all aspects of your running gait however the
          majority of clients I see who think they have had a gait analysis have
          not. What they actually had was someone briefly watch them run up the
          street or on a treadmill and advising on what shoes in their opinion
          would suit their running. There is a place for this but it is not an
          'analysis' it's advice based on what they see. Have you ever asked the
          person doing it what they studied for them to come to their
          conclusion? You may be lucky and get someone who has studied for years
          and understands what they see and are able to make a decision from
          seeing you run a few steps but if that's the case why are they now
          using that knowledge to sell trainers? The truth is the person may
          have been on a course lasting anywhere from a couple of hours to a
          couple of days, who knows they may be a student working part time in
          the shop to fund a degree that is not related to running at all, you
          don't know unless you ask. Don't just assume that because they work in
          a shop that sells trainers that they have more knowledge than you on
          what is best for you. Of course listen to their advice, ask questions
          and then make your own decision. Just remember this is not a full gait
          analysis it's someone helping you decide which trainers to wear.
        </Typography>
        <Typography className={classes.p}>
          A full gait analysis involves looking at your body as a whole, after
          all it's all connected! In my clinic I video clients and then review
          their gait in slow motion from different angles. I look at people
          running fresh and fatigued as many runners' problems occur mid way
          though a run, how else can you know what may be causing problems later
          on in a run unless you see someone running under fatigue. I would then
          combine these results with the results from a set of tests that reveal
          in more detail any muscle tightnesses, weaknesses and imbalances. This
          type of analysis will always highlight imbalances but the key is to
          know what changes could be made that may reduce injury risk and
          improve running economy. Other small 'imperfections' or asymmetries
          may not be limiting an athlete sufficiently to warrant trying to
          change.{" "}
        </Typography>

        <img src={gait_analysis} className={classes.img} alt="gait" />

        <Typography variant="h5">Gait analysis - is it for you?</Typography>

        <Typography className={classes.p}>
          Gait analysis is not cheap, but then again neither is entry to a major
          city marathon and if you are training for a pb and are sacrificing
          time with friends and family then yes, having a gait analysis is a
          worth the money as it will help you reach your goals and highlight any
          potential injury risks. You don't need to be an elite athlete to have
          it done either. If you want to progress and improve your running
          economy it is a very interesting thing to do and you will surprised at
          how you can improve your running without your fitness changing so
          imagine what you can do with a good training plan combined with
          knowing areas of your gait that can be improved. The other main reason
          why athletes have their gait analysed is if they suffer from injury.
          In these cases I would recommend it as it could highlight key
          contributing factors to their injury.{" "}
        </Typography>
        <Typography className={classes.p}>
          After having your gait analysis you should be given exercises and
          training cues that will help you alter your gait if it is needed. It
          may not be a quick fix, if there are muscle weaknesses and mobility
          restrictions these take time to correct but if you are prepared to put
          the effort in with a few key exercises you will reap the rewards. In
          some cases just highlighting a few areas and showing the athlete
          simple cues to help them correct a movement can result significant
          changes. It all goes back to what I said at the beginning, we are all
          different and so are everyones results.
        </Typography>

        <Typography className={classes.p}>
          If you would like to know more about having a gait analysis click here
        </Typography>
      </Paper>
    </div>
  );
};

export default Article;
