import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { indigo, orange } from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { Route, HashRouter, Switch } from "react-router-dom";
import Home from "./pages/home";
import Prices from "./pages/prices";
import Location from "./pages/location";
import Header from "./components/header";
import Drawer from "./components/drawer";
import Banner from "./components/banner";
import Footer from "./components/footer";
import Layout from "./components/layout";
import About from "./pages/about";
import Faq from "./pages/faq";
import Services from "./pages/services";
import Article from "./pages/article";
import ArticleOperations from "./pages/articleOperations";
import ArticleGait from "./pages/articleGaitAnalysis";
import ArticleKTape from "./pages/articleKTape";
import ArticleTRX from "./pages/articleTRX";

const styles = createStyles({});

const AppWrapperWithoutStyles = () => {
  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: indigo,
      secondary: orange,
    },
    typography: {
      h1: {
        fontWeight: 500,
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 500,
      },
      h4: {
        fontWeight: 500,
      },
    },
  });

  const [open, setOpen] = React.useState<boolean>(false);

  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <HashRouter>
          <Drawer open={open} setOpen={setOpen} />
          <Layout>
            <Header openSidebar={() => setOpen(true)} />

            <Banner />

            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/prices" component={Prices} />
              <Route exact path="/faqs" component={Faq} />
              <Route exact path="/location" component={Location} />
              <Route exact path="/about" component={About} />
              <Route
                exact
                path="/articles/gait-analysis-do-i-need-it"
                component={Article}
              />
              <Route
                exact
                path="/articles/pre-post-operation-rehabilitation"
                component={ArticleOperations}
              />
              <Route
                exact
                path="/articles/gait-analysis"
                component={ArticleGait}
              />
              <Route
                exact
                path="/articles/kinesiology-taping"
                component={ArticleKTape}
              />
              <Route
                exact
                path="/articles/trx-suspension-training"
                component={ArticleTRX}
              />
            </Switch>

            <Footer />
          </Layout>
        </HashRouter>
      </div>
    </MuiThemeProvider>
  );
};

const App = withStyles(styles)(AppWrapperWithoutStyles);

export default App;
