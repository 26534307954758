import React from "react";
import GoogleMapReact from "google-map-react";
import pin from "../images/logo_small.png";
import { makeStyles } from "@material-ui/core";

interface PinProps {
  lat: number;
  lng: number;
}

const Pin = ({ lat, lng }: PinProps) => {
  return (
    <div>
      <img src={pin} alt="pin" />
    </div>
  );
};

interface MapProps {
  lat: number;
  lng: number;
  defaultZoom: number;
}

const Map: React.FunctionComponent<MapProps> = ({
  lat,
  lng,
  defaultZoom,
}: MapProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBKSqhIfKlxprG-EAA6R4g24GB4J8KoB5g" }}
        defaultCenter={{ lat: lat, lng: lng }}
        defaultZoom={defaultZoom}
      >
        <Pin lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    height: "500px",
    width: "100%",
  },
});

export default Map;
