import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import ktape from "../images/kinesio-tape.jpg";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  header: {
    marginLeft: "45px",
  },
  article: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const ArticleKTape = () => {
  const classes = useStyles();
  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        Kinesiology Taping
      </Typography>
      <Paper className={classes.article}>
        <Typography className={classes.p}>
          Kinesiology taping has hit the headlines after it was seen on many
          athletes at the 2008 Beijing Olympics. Its was actually developed over
          the 30 years ago by Dr Kenzo Kase in Japan and since then has been
          used world wide in both clinical and sporting settings.
        </Typography>

        <img src={ktape} className={classes.img} alt="ktape" />

        <Typography variant="h5">What does it do?</Typography>
        <Typography className={classes.p}>
          Kinesiology tape is different to traditional tape, it is lighter and
          can be kept on the skin for longer periods of time. The application of
          the tape is dictated by the finding found from a clinical assessment.
          The tape can be used as a rehabilitation tool alongside other
          treatments, it is often applied prior to activity or rehabilitation
          exercises. The tape can be used to re­-educate the neuromuscular
          system, reduce pain and inflammation, enhance performance, prevent
          injury and promote good circulation and healing and assist in
          returning the body to homeostasis.
        </Typography>

        <Typography className={classes.p}>
          Cassie uses kinesiology tape during treatment sessions and also offers
          a taping service for clients wishing to be taped for specific
          competitions, matches or even if they have a weekend of gardening
          planned!
        </Typography>
      </Paper>
    </div>
  );
};

export default ArticleKTape;
