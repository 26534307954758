import React from "react";
import { makeStyles } from "@material-ui/core";
import banner from "../images/Banner.png";

const useStyles = makeStyles(() => ({
  root: {
    padding: "20px",
    background: "#313131",
  },
  banner: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
  },
}));

const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={banner} alt="banner" className={classes.banner} />
    </div>
  );
};

export default Banner;
