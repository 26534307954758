import React from "react";
import {
  Drawer as MaterialDrawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
} from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PaymentIcon from "@material-ui/icons/Payment";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import GridOnIcon from "@material-ui/icons/GridOn";
import ArrowRight from "@material-ui/icons/ArrowRight";
import { Link } from "react-router-dom";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface DrawerProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

interface MenuItem {
  text: string;
  Icon: React.ComponentType<SvgIconProps>;
  url: string;
  children?: MenuItem[];
}

const useStyles = makeStyles((theme) => ({
  button: {
    textDecoration: "none",
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
  },
  grow: {
    flexGrow: 1,
  },
  text: {
    color: theme.palette.text.primary,
    "& span": { fontFamily: "Comic Sans MS" },
  },
}));

const Drawer: React.FunctionComponent<DrawerProps> = (props: DrawerProps) => {
  const { open, setOpen } = props;

  const classes = useStyles();

  const menu: MenuItem[] = [
    { text: "Home", Icon: HomeIcon, url: "/" },
    {
      text: "What We Offer",
      Icon: GroupIcon,
      url: "/services",
      children: [
        {
          text: "Treatment & Rehabilitation",
          url: "/services",
          Icon: ArrowRight,
        },
        { text: "Performance Enhancement", url: "/services", Icon: ArrowRight },
        {
          text: "Prevention/Maintenance Programs",
          url: "/services",
          Icon: ArrowRight,
        },
        { text: "Sport Massage", url: "/services", Icon: ArrowRight },
        {
          text: "Pre/Post Operation Rehabilitation",
          url: "/articles/pre-post-operation-rehabilitation",
          Icon: ArrowRight,
        },
        {
          text: "Gait Analysis",
          url: "/articles/gait-analysis",
          Icon: ArrowRight,
        },
        {
          text: "Kinesiology Taping",
          url: "/articles/kinesiology-taping",
          Icon: ArrowRight,
        },
        {
          text: "TRX",
          url: "/articles/trx-suspension-training",
          Icon: ArrowRight,
        },
      ],
    },
    { text: "Location", Icon: LocationOnIcon, url: "/location" },
    { text: "Prices", Icon: PaymentIcon, url: "/prices" },
    { text: "FAQs", Icon: QuestionAnswerIcon, url: "/faqs" },
    { text: "About Us", Icon: InfoIcon, url: "/about" },
  ];

  const MenuLink = ({ url, text, Icon }: MenuItem) => {
    return (
      <Link to={url} className={classes.button}>
        <ListItem button key={text}>
          <ListItemIcon>
            <Icon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={text} className={classes.text} />
        </ListItem>
      </Link>
    );
  };

  return (
    <MaterialDrawer open={open} onClose={() => setOpen(false)}>
      <div
        tabIndex={0}
        role="button"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
        className={classes.flexBox}
      >
        <List>
          {menu.map((menuItem: MenuItem, index: number) => (
            <div>
              <MenuLink {...menuItem} key={index} />

              {menuItem.children &&
                menuItem.children.map((child, cIndex) => (
                  <MenuLink {...child} key={cIndex} />
                ))}
            </div>
          ))}
          ]
        </List>
      </div>
    </MaterialDrawer>
  );
};

export default Drawer;
