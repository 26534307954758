import React from "react";
import cassie from "../images/cassie.jpg";
import {
  makeStyles,
  Grid,
  Link,
  Paper,
  Typography,
  List,
  Hidden,
} from "@material-ui/core";
import Bullet from "../components/bullet";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  container: {
    margin: "45px",
    padding: "45px",
  },
  header: {
    marginLeft: "45px",
  },
  image: {
    maxWidth: "100%",
  },
});

const About = () => {
  const classes = useStyles();

  return (
    <div>
      <ScrollToTop />
      <Typography className={classes.header} variant="h3">
        About Us
      </Typography>
      <Paper className={classes.container}>
        <Grid container spacing={5}>
          <Hidden only={["xs", "sm"]}>
            <Grid item md={3}>
              <img className={classes.image} src={cassie} alt="run" />
            </Grid>
          </Hidden>
          <Grid item md={9}>
            <Description />
          </Grid>
          <Hidden only={["md", "lg", "xl"]}>
            <Grid item md={3}>
              <img className={classes.image} src={cassie} alt="run" />
            </Grid>
          </Hidden>
        </Grid>
      </Paper>
    </div>
  );
};

const Description = () => {
  return (
    <div id="description">
      <h3>Cassie Escott BSc(Hons) GSR</h3>
      <div>
        <div>
          <p>
            Cassie graduated from St Marys College, University of Surrey with a
            degree in Sport Rehabilitation in 2004 and is a member of{" "}
            <Link
              href="http://www.basrat.org"
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
            >
              BASRAT
            </Link>{" "}
            (British Association of Sport Rehabilitators and Trainers). She has
            worked with professional athletes from a wide range of sports
            including basketball, hockey, horse riding, cycling and motor sports
            to name a few. Cassie was selected to be a medical volunteer at the
            Glasgow 2014 Commonwealth Games where she worked in the gymnastics
            and netball athlete medical room. She has experience working with
            private patients since 2004 and has an established client base.
            Throughout her career Cassie has worked in clinics in Surrey and for
            a number of years at a private practice in Harley Street, London.
            Cassie now works mostly from a clinic based at her home in Hersham,
            Surrey.
          </p>
          <p>
            Cassie specialises in the treatment and prevention of
            musculoskeletal pain and injury. Her specific interests lie in
            combining treatment modalities (massage, ultrasound, Kinesiology
            taping) with structured corrective exercise training. This helps
            give the client a rounded approach to their treatment with the aim
            of giving them an understanding of their injury whilst educating
            them in ways in which will not only aid recovery but also reduce the
            risk of re-injury. Examples of conditions which will benefit from
            treatment are:
          </p>
          <List>
            {[
              "Knee Pain",
              "Back Pain",
              "Muscle stiffness/tightness",
              "Shin splints",
              "Plantar fasciitis",
              "Recovery following broken bones",
              "Repetitive strain injuries",
              "Tennis elbow",
            ].map((text) => (
              <Bullet text={text} />
            ))}
          </List>
          <p>
            Cassie also works with individuals who wish to learn about injury
            prevention techniques, where she identifies weaknesses in posture
            and lifestyle and then seeks to improve these via a combination of
            manual therapy techniques, stretching and exercise education.
            Individuals who may benefit from this sort of session are:
          </p>
          <List>
            {[
              "Desk bound workers who spend long period sitting which may lead to postural changes and muscle imbalances to individuals in active occupations such as builders who rely on being able to use their body daily to fulfil their occupational needs.",
              "Older adults who want to maintain functional independence and quality of life both of which are directly linked to maintaining good range of motion, flexibility and strength which often decline with age.",
              "Weekend warriors, individuals that compete at a sport whilst working full time. Cassie can advise on how best to use the time these individuals do have to the best possible use to support their bodies through the challenges they choose to put themselves through!",
            ].map((text) => (
              <Bullet text={text} />
            ))}
          </List>
          <p>
            Cassie’s experience with{" "}
            <Link
              href="/services#Pre_Post_Operation_Rehabilitation"
              color="textPrimary"
            >
              post and pre-operative rehabilitation
            </Link>{" "}
            will benefit anyone wanting to optimise recovery following surgery.
            She will help you prepare for surgery and educate you on how to get
            your body into the best condition which then leads to improved
            overall outcome. Examples of surgeries benefitting in this approach
            are
          </p>
          <List>
            {[
              "Knee replacement",
              "Hip replacement",
              "ACL reconstruction",
              "Shoulder surgery",
              "Knee arthroscopy",
              "Fracture reconstruction",
            ].map((text) => (
              <Bullet text={text} />
            ))}
          </List>
          <p>Cassie is also qualified in and can offer</p>
          <List>
            {[
              "Full running gait analysis",
              "Sports massage",
              "TRX instruction",
              "Kinesiology taping",
            ].map((text) => (
              <Bullet text={text} />
            ))}
          </List>
          <p>
            When not working at her own clinic Cassie is part of the medical
            team at Chelsea FC Centre of Excellence and Chelsea FC Foundation.
            This involves running clinics for injured players as well as
            pitchside cover for training and matches.
          </p>
          <p>
            Outside of work Cassie is a keen sports women. She played hockey in
            the English Premier league and then went on to compete nationally
            and internationally as a track cyclist. More recently Cassie has
            started competing in ultra marathon events. Cassie’s experience as
            an athlete means that she understands the demands and needs of
            athletes she treats. This is of huge importance when working with
            athletes preparing for particular events or trying to recover from
            injury prior to an event.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
