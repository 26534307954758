import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Container,
} from "@material-ui/core";

interface LayoutProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const classes = props.classes;

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <div className={classes.container}>{props.children}</div>
      </Container>
    </React.Fragment>
  );
};

const styles = createStyles({
  container: {
    backgroundColor: "#313131",
  },
});

export default withStyles(styles)(Layout);
