import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginLeft: "45px",
    },
    heading: {
      fontWeight: "bold",
    },
    container: {
      margin: "45px",
      padding: "45px",
      backgroundColor: "#222222",
    },
  })
);

interface Question {
  title: string;
  details: string;
}

interface QuestionProps extends Question {
  id: string;
}

const faqs: Question[] = [
  {
    title: "Do I need to do anything before my appointment?",
    details:
      "If you have any medical conditions which may affect your treatment plan it is advised to check with your GP before your appointment, however this is not compulsory for all clients and if you are unsure Cassie will advise you as to whether this is necessary when you book your appointment",
  },
  {
    title: "What should I bring/wear to my appointment?",
    details:
      "For your first appointment it advised to wear appropriate underwear or a pair of shorts and cropped top for women as you may be required to undress when being assessed. Cassie will advise you on what to wear and bring when you book your appointment.",
  },
  {
    title: "Can I use my private health insurance to pay for my treatments?",
    details:
      "Most healthcare insurers will only cover treatments by HPC registered practitioners, currently BASRaT is in the process of registration but at present BASRaT member are unable to claim payment through insurance companies. For more information please see BASRaT.",
  },
  {
    title: "How many sessions will I need?",
    details:
      "This is difficult to answer as every individual is different. If you are having sports massage treatments generally people have a few sessions close together when they start and then work out how long their body needs between sessions (generally between 28 weeks). For injury treatments, following your assessment Cassie will discuss with you a treatment strategy with a suggested timescale however this is just a guideline and is dependent on how you respond to treatment and your commitment to carrying out any home exercises you are prescribed.",
  },
  {
    title: "Can I bring children to my appointment?",
    details:
      "We understand that childcare can be a problem, bringing children to treatments sessions can be a distraction for the client. However if they feel that their child will be able to occupy themselves for the duration of the session they are welcome to come along (due to the size of the treatment room only there is only space for one child in addition to the client).",
  },
  {
    title: "What do I do if I have questions between appointments?",
    details:
      "Cassie works very closely with her clients she find it helps their progress to keep in contact between sessions. If you have any questions in between your appointments you can contact Cassie by Email, phone, text or WhatsApp.",
  },
];

const Faq = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const Question: React.FC<QuestionProps> = ({ id, title, details }) => (
    <ExpansionPanel expanded={expanded === id} onChange={handleChange(id)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        id={`${id}_content`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography>{details}</Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  return (
    <div>
      <ScrollToTop />
      <Typography className={classes.header} variant="h3">
        FAQs
      </Typography>
      <Paper className={classes.container}>
        {faqs.map((question, i) => (
          <Question id={`${i}_panel`} {...question} />
        ))}
      </Paper>
    </div>
  );
};

export default Faq;
