import React from "react";
import { makeStyles, Grid, Paper, Typography, Button } from "@material-ui/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import _1 from "../images/IP/1.jpg";
import _2 from "../images/IP/2.jpg";
import _3 from "../images/IP/3.jpg";
import _4 from "../images/IP/4.jpg";
import pain from "../images/pain.jpg";
import rehab from "../images/rehab.jpg";
import gait from "../images/gait.jpg";
import { useHistory } from "react-router-dom";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "20px",
    padding: "45px",
    flexGrow: 1,
  },
  carousel: {
    maxWidth: "700px",
    margin: "0 auto",
  },
  paper: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "430px",
    },
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: "flex",
    flexDirection: "column",
  },
  spacer: { flexGrow: 3 },
  highlightImg: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "90%",
  },
  btn_text: { color: "white" },
  dark: {
    backgroundColor: "#222222",
    paddingTop: "20px",
    paddingBottom: "10px",
  },
  hero: {
    display: "block",
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "90%",
    margin: "20px auto",
  },
}));

const Home = () => {
  const classes = useStyles();
  const history = useHistory();

  const pics = [_1, _2, _3, _4];

  const highlights = [
    {
      header: "What We Do",
      para_1:
        "Positive Movement specialises in the treatment and rehabilitation of musculoskeletal pain and injury.",
      para_2: "Read on to see exactly what services we offer.",
      img: pain,
      btn_text: "Show me...",
      url: "./services",
    },
    {
      header: "Rehab for Surgery",
      para_1:
        "Prepare your body for an operation - improve mobility, strength and flexability.",
      para_2: "Reduce pain and improve recovery post surgery.",
      img: rehab,
      btn_text: "Find out more...",
      url: "./services",
    },
    {
      header: "Positive Focus",
      para_1:
        "This month Cassie discusses the benefits gait analysis. Do you need it?",
      para_2: "Read on to see exactly what Positive Movement offers.",
      img: gait,
      btn_text: "Read more...",
      url: "./articles/gait-analysis-do-i-need-it",
    },
  ];

  const hero = pics[Math.floor(Math.random() * 10) % 4];

  return (
    <div>
      <div className={classes.dark}>
        <div className={classes.carousel}>
          <ScrollToTop />
          <Paper className={classes.dark}>
            <img className={classes.hero} src={hero} alt="hero" />
          </Paper>
        </div>
      </div>

      <div className={classes.container}>
        <Grid container spacing={3}>
          {highlights.map((x, i) => (
            <Grid item sm={4}>
              <Paper className={classes.paper}>
                <img
                  src={x.img}
                  alt="highlight"
                  className={classes.highlightImg}
                />
                <Typography variant="h6" noWrap>
                  {x.header}{" "}
                </Typography>
                <Typography>{x.para_1}</Typography>
                <br />
                <Typography>{x.para_2}</Typography>
                <div className={classes.spacer}></div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.btn_text}
                  onClick={() => history.push(x.url)}
                >
                  {x.btn_text}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Home;
