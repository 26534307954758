import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import crutches from "../images/crutches.jpeg";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  header: {
    marginLeft: "45px",
  },
  article: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const ArticleOperations = () => {
  const classes = useStyles();
  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        Pre/Post Operation Rehabilitation
      </Typography>
      <Paper className={classes.article}>
        <Typography className={classes.p}>
          Cassie has a keen interest in the area of pre and post operative
          rehabilitation. It is proven that preparing your body prior to an
          operation (joint replacements, ligament reconstruction) with a
          structured and safe exercise regime will improve mobility, reduce pain
          and improve quality of life post surgery.
        </Typography>
        <Typography variant="h5">Joint Replacement</Typography>
        <Typography className={classes.p}>
          Having a joint replaced will usually mean that you are already in a
          significant amount of pain and have had to adapt areas of your life to
          compensate for the affected joint. This however does not mean that you
          cannot still exercise the joint and surrounding areas in preparation
          for your surgery. By doing this you will help prepare the joint for
          surgery as well as prepare the rest of your body for the recovery
          period. Following surgery you can then progress onto a progressive
          plan which is re-assesed regularly and progressed in line with your
          recovery rate. By working this was you will reduce your recovery time
          and improve the overall outcome of your surgery.
        </Typography>

        <img src={crutches} className={classes.img} alt="crutches" />

        <Typography variant="h5">Ligament Surgery</Typography>
        <Typography className={classes.p}>
          Commonly with ligament injuries (such as ACL tears) which require
          surgical repair there will be a period of time between diagnosis and
          surgery to allow for reduction in swelling to the joint. During this
          period it is very important to regain joint range of motion and
          prepare the joint for surgery by strengthening the surrounding areas
          and correcting and muscular imbalances and compensatory movement
          patterns that develop following injury. This is done by a structured
          exercise plan designed for your specific injury. The plan is designed
          to work the joint in safe movements and prepare it for surgery.
          Following surgery you will continue with your post surgery plan that
          will be progressed regularly in order to regain functional use of the
          joint to required level (depending on age, sport, occupation).
        </Typography>

        <Typography className={classes.p}>
          Post and pre operative rehabilitation exercise plans are designed to
          fit in with each individual's needs this may mean home visits to
          design a plan that can be done at home whilst the client is unable to
          drive. They can also include gym and pool based exercise if needed.
        </Typography>
        <Typography className={classes.p}>
          Sometimes manual therapy is also used pre and post surgery to aid in
          preparing the body and releasing tight muscles and improving joint
          mobility pre and post surgery.
        </Typography>
      </Paper>
    </div>
  );
};

export default ArticleOperations;
