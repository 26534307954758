import React from "react";
import { Typography, Grid, Paper, Link, makeStyles } from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import WebIcon from "@material-ui/icons/Public";
import Map from "../components/map";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  icon: {
    paddingRight: "20px",
  },
  root: {
    margin: "45px",
  },
  container: {
    marginTop: "20px",
    padding: "45px",
  },
  padTop: {
    marginTop: "20px",
  },
});

const Location = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ScrollToTop />
      <Typography variant="h3">Location</Typography>
      <Paper className={classes.container}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="h5">The Positive Movement Practice</Typography>

            <div className={classes.padTop}>
              <Typography noWrap>
                86 Pleasant Place
                <br />
                Hersham
                <br />
                Walton-on-Thames
                <br />
                Surrey
                <br />
                KT12 4HX
              </Typography>
            </div>

            <div className={classes.padTop}>
              <PhoneIcon className={classes.icon} />

              <Link
                color="textPrimary"
                underline="hover"
                href="tel:+447990552842"
              >
                07990 552 842
              </Link>
            </div>
            <div>
              <EmailIcon className={classes.icon} />

              <Link
                color="textPrimary"
                underline="hover"
                href="mailto:cassie@positivemovement.co.uk"
              >
                cassie@positivemovement.co.uk
              </Link>
            </div>
            <div>
              <WebIcon className={classes.icon} />

              <Link
                color="textPrimary"
                underline="hover"
                href="http://www.positivemovement.co.uk"
                target="_blank"
              >
                www.positivemovement.co.uk
              </Link>
            </div>
          </Grid>

          <Grid item md={6}>
            <Map lat={51.3619987} lng={-0.399974} defaultZoom={14} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Location;
