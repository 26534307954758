import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import AdjustIcon from "@material-ui/icons/Adjust";

const useStyles = makeStyles({
  bullet: {
    color: "#FF5E00",
  },
  listItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});

interface BulletProps {
  text: string;
}

const Bullet: React.FC<BulletProps> = ({ text }) => {
  const classes = useStyles();
  return (
    <ListItem className={classes.listItem}>
      <ListItemIcon>
        <AdjustIcon className={classes.bullet} />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

export default Bullet;
