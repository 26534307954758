import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Hidden,
  makeStyles,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
  MenuItem,
  Divider,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../images/logo_small.png";

const useStyles = makeStyles({
  root: {
    background:
      "linear-gradient(0deg, rgba(31,31,31,1) 67%, rgba(77,76,75,1) 100%)",
  },
  navButton: {
    color: "#999999",
    fontFamily: "Comic Sans MS",
    "&:hover": {
      color: "white",
    },
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    margin: "0 20px 0 0",
    cursor: "pointer",
  },
  menuButton: {
    marginLeft: -12,
    fontFamily: "Comic Sans MS",
  },
  brand: {
    fontFamily: "Comic Sans MS",
    cursor: "pointer",
  },
});

interface HeaderProps {
  openSidebar: () => void;
}

interface NavButtonProps {
  text: string;
  url: string;
}

interface NavButtonWithChildrenProps {
  text: string;
  children: NavButtonProps[][];
}

const NavButton = ({ text, url }: NavButtonProps) => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (url: string) => {
    history.push(url);
  };

  return (
    <Button onClick={() => navigate(url)} className={classes.navButton}>
      {text}
    </Button>
  );
};

const NavButtonWithChildren = ({
  text,
  children,
}: NavButtonWithChildrenProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const navigate = (url: string) => {
    history.push(url);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        className={classes.navButton}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {text}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {children.map((group, i_group) => {
                    return (
                      <React.Fragment>
                        {group.map((x, i) => (
                          <MenuItem
                            key={`menu_item_${i}`}
                            className={classes.brand}
                            onClick={(e) => {
                              navigate(x.url);
                              handleClose(e);
                            }}
                          >
                            {x.text}
                          </MenuItem>
                        ))}

                        {i_group < children.length && <Divider />}
                      </React.Fragment>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const Header = ({ openSidebar }: HeaderProps) => {
  const classes = useStyles();
  const history = useHistory();

  const navigate = (url: string) => {
    history.push(url);
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={openSidebar}
        >
          <MenuIcon />
        </IconButton>

        <img
          className={classes.logo}
          src={logo}
          onClick={() => navigate("/")}
          alt="logo"
        />
        <Typography
          variant="h5"
          noWrap
          className={classes.brand}
          onClick={() => navigate("/")}
        >
          POSITIVE MOVEMENT
        </Typography>

        <Hidden smDown>
          <div className={classes.grow} />
          <NavButtonWithChildren
            text="What We Offer"
            children={[
              [
                { text: "Treatment & Rehabilitation", url: "/services" },
                { text: "Performance Enhancement", url: "/services" },
                { text: "Prevention/Maintenance Programs", url: "/services" },
                { text: "Sport Massage", url: "/services" },
              ],
              [
                {
                  text: "Pre/Post Operation Rehabilitation",
                  url: "/articles/pre-post-operation-rehabilitation",
                },
              ],
              [{ text: "Gait Analysis", url: "/articles/gait-analysis" }],
              [
                {
                  text: "Kinesiology Taping",
                  url: "/articles/kinesiology-taping",
                },
                { text: "TRX", url: "/articles/trx-suspension-training" },
              ],
            ]}
          />
          <NavButton text="Location" url="/location" />
          <NavButton text="Prices" url="/prices" />
          <NavButton text="FAQs" url="/faqs" />
          <NavButton text="About Us" url="/about" />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
