import React from "react";
import { Paper, Typography, makeStyles, Grid } from "@material-ui/core";
import gait_analysis1 from "../images/gait-analysis.jpg";
import gait_analysis2 from "../images/gait-analysis-2.jpg";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles({
  header: {
    marginLeft: "45px",
  },
  article: {
    padding: "45px",
    margin: "45px",
    backgroundColor: "#252525",
  },
  img: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const ArticleGait = () => {
  const classes = useStyles();
  return (
    <div>
      <ScrollToTop />
      <Typography variant="h3" className={classes.header}>
        Gait Analysis
      </Typography>
      <Paper className={classes.article}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Typography variant="h5">
              Who benefits from gait analysis?
            </Typography>
            <Typography className={classes.p}>
              <ul>
                <li>
                  Runners/triathletes interested in improving your running
                  economy and reducing risk of injury{" "}
                </li>
                <li>
                  Runners that suffers from recurrent running related injuries{" "}
                </li>
                <li>New to running/jogging and wanting to improve</li>
                <li>Runners wanting to add something new to their training</li>
              </ul>
            </Typography>

            <Typography variant="h5">What is a gait analysis?</Typography>
            <Typography className={classes.p}>
              Gait analysis is a methodical process which sets out to determine
              how efficiently an individual runs. We all have our own ‘style’ of
              running that we develop as we grow up, styles may be influenced by
              past injuries, what we do for a living and and our athletic
              history. We take all this into account when carrying out the
              analysis and use results to help you improve certain aspects of
              your running.
            </Typography>

            <Typography variant="h5">What does it involve?</Typography>
            <Typography className={classes.p}>
              Functional movement screening to assess any muscle imbalances,
              weaknesses and/or tightnesses that may be affecting your running.
              Comprehensive video analysis of your running where we look at your
              whole body and how you take every stride.
            </Typography>

            <Typography variant="h5">How long does it take?</Typography>
            <Typography className={classes.p}>
              Your gait analysis will take place over 2 hours which can be split
              over 2 seperate sessoins. The first session will involve the
              functional movement screening and video analysis. If you run long
              distances this may involve a mid distance run so you can be
              assessed when fatigued. The second session will involve going
              through your results and giving you exercises and coaching advice
              based on the findings.
            </Typography>

            <Typography variant="h5">
              What do I do after the initial analysis?
            </Typography>
            <Typography className={classes.p}>
              Following your initial analysis you will be given advice on how to
              improve your running gait with training drills and exercises. We
              also offer one-to-one gait coaching sessions where we can work on
              the specifics of your technique.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <img src={gait_analysis1} className={classes.img} alt="gait" />
            <img src={gait_analysis2} className={classes.img} alt="gait2" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ArticleGait;
